import React, { useEffect, useState, useContext, useCallback } from 'react';
import { env } from 'constants/env';
import {
  PaymentContext,
  AdyenPropsType,
  AdyenCreditCardResponse,
  AdyenACHResponse,
} from 'V2/Steps/PaymentDetails';
import { useSubmit, useApplyProvider } from 'V2/Apply';
import { buildFieldDataPayload } from 'V2/Step';
import { AdyenCheckout } from '../getAdyenConfig';

declare global {
  interface Window {
    AdyenCheckout: any;
  }
}

type BillingAddress = {
  numberOrName?: string;
  street?: string;
  city?: string;
  state?: string;
  postalCode: string;
  countryCode?: string;
};

type FormattedCardData = {
  type: 'ENCRYPTED_CREDIT_CARD' | 'ENCRYPTED_ACH';
  cardHolder: string;
  number: string;
  cvc: string;
  expirationMonth: string;
  expirationYear: string;
  billingAddress?: BillingAddress;
};

const isACH = (
  e: AdyenCreditCardResponse | AdyenACHResponse
): e is AdyenACHResponse => e.data.paymentMethod.type === 'ach';

function buildCardDetails(
  adyenEncryptedData: AdyenCreditCardResponse
): FormattedCardData {
  const cardDetails: FormattedCardData = {
    type: 'ENCRYPTED_CREDIT_CARD',
    cardHolder: adyenEncryptedData.data.paymentMethod.holderName,
    number: adyenEncryptedData.data.paymentMethod.encryptedCardNumber,
    cvc: adyenEncryptedData.data.paymentMethod.encryptedSecurityCode,
    expirationMonth: adyenEncryptedData.data.paymentMethod.encryptedExpiryMonth,
    expirationYear: adyenEncryptedData.data.paymentMethod.encryptedExpiryYear,
  };

  const adyenBillingAddress = adyenEncryptedData.data.billingAddress;

  if (adyenBillingAddress?.postalCode) {
    // If there's at least a postal code, try taking the full address. Only postalCode is required, though:
    const billingAddress: BillingAddress = {
      postalCode: adyenBillingAddress.postalCode,
      numberOrName: adyenBillingAddress.houseNumberOrName,
      street: adyenBillingAddress.street,
      city: adyenBillingAddress.city,
      state: adyenBillingAddress.stateOrProvince,
      countryCode: adyenBillingAddress.country,
    };

    cardDetails.billingAddress = billingAddress;
  }

  return cardDetails;
}

export const Adyen: React.FC<AdyenPropsType> = ({ config }) => {
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
  const [checkout, setCheckout] = useState<AdyenCheckout>();
  const [submitingBind, setSubmitingBind] = useState<boolean>(false);
  const [submitBindPromise, setSubmitBindPromise] = useState<any>(null);

  const {
    setAdyen,
    setFormLoaded,
    formLoaded,
    fields,
    isDeactivating,
    documents,
    setIsSubmitting,
  } = useContext(PaymentContext);
  const {
    app: {
      status: { current_step_submit_uri: submitUri },
    },
  } = useApplyProvider();
  const { submit, setStep: setStepSubmit } = useSubmit();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setStep = useCallback(setStepSubmit, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const submitForm = useCallback(
    (url, payload) => {
      if (!submitingBind && !submitBindPromise) {
        const bindPromise = submit(url, payload);
        setSubmitBindPromise(bindPromise);
        return bindPromise;
      }
    },
    [submit, submitBindPromise, submitingBind]
  );

  const paymentToken = fields.find((f) => f.field_id === 'payment_token')!;
  const [loaded, setLoaded] = useState(false);

  const removeDropin = () => {
    const adyenDropins = Array.from(
      document.getElementsByClassName('adyen-checkout__dropin')
    );

    for (let i = 0; i < adyenDropins.length - 1; i++) {
      adyenDropins[i].remove();
    }
  };

  useEffect(() => {
    let link: any, script: any;

    // Load and inject Adyen CSS.
    if (!document.getElementById('adyenStylesheet')) {
      link = document.createElement('link');
      link.id = 'adyenStylesheet';
      link.rel = 'stylesheet';
      link.href = `https://checkoutshopper-${env.adyenEnvironment}.adyen.com/checkoutshopper/sdk/${env.adyenVersion}/adyen.css`;
      link.crossOrigin = 'anonymous';
      link.integrity = env.adyenCssHash;
      document.head.appendChild(link);
    }

    // Load and inject Adyen SDK asynchronously.
    if (!document.getElementById('adyenScript')) {
      script = document.createElement('script');
      script.id = 'adyenScript';
      script.src = `https://checkoutshopper-${env.adyenEnvironment}.adyen.com/checkoutshopper/sdk/${env.adyenVersion}/adyen.js`;
      script.async = true;
      script.integrity = env.adyenScriptHash;
      script.crossOrigin = 'anonymous';
      script.onload = () => setScriptLoaded(true);
      document.body.appendChild(script);
    }

    return () => {
      document.body.removeChild(script);
      document.head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    async function initializeAdyenCheckout() {
      if (scriptLoaded && !formLoaded && !loaded) {
        const adyenConfiguration = {
          ...config,
          onError: () => {
            setTimeout(() => setIsSubmitting(false), 0);
          },
          onSubmit: async (e: AdyenCreditCardResponse | AdyenACHResponse) => {
            const nonSignatureFields = fields.filter(
              (f) => f.entity_type.id !== 'signature'
            );

            const documentFields = documents.flatMap((doc) => doc.field_data);

            const fieldData = [...nonSignatureFields, ...documentFields];

            const payload = buildFieldDataPayload(
              fieldData.map((f) => {
                if (f.field_id === 'payment_token') {
                  if (isACH(e)) {
                    return {
                      ...f,
                      value: JSON.stringify({
                        type: 'ENCRYPTED_ACH',
                        accountNumber:
                          e.data.paymentMethod.encryptedBankAccountNumber,
                        routingNumber:
                          e.data.paymentMethod.encryptedBankLocationId,
                        accountOwner: e.data.paymentMethod.ownerName,
                        billingAddress: {
                          city: e.data.billingAddress.city,
                          countryCode: e.data.billingAddress.country,
                          numberOrName: e.data.billingAddress.houseNumberOrName,
                          street: e.data.billingAddress.street,
                          state: e.data.billingAddress.stateOrProvince,
                          postalCode: e.data.billingAddress.postalCode,
                        },
                      }),
                    };
                  } else {
                    return {
                      ...f,
                      value: JSON.stringify(buildCardDetails(e)),
                    };
                  }
                } else {
                  return f;
                }
              })
            );

            if (!submitingBind) {
              try {
                setSubmitingBind(true);
                const submitResult = await submitForm(submitUri, payload);
                if (submitResult) {
                  setStep(submitResult);
                }
              } catch (error) {
                setSubmitingBind(false);
                setSubmitBindPromise(null);
              }
            }
          },
        };
        setCheckout(await window.AdyenCheckout(adyenConfiguration));
      }
    }

    initializeAdyenCheckout();
  }, [
    scriptLoaded,
    config,
    setFormLoaded,
    submitForm,
    formLoaded,
    fields,
    paymentToken,
    loaded,
    setStep,
    submitUri,
    documents,
    setIsSubmitting,
    submitingBind,
  ]);

  React.useEffect(() => {
    if (!checkout) return;

    const dropin = checkout
      .create('dropin', {
        onReady: () => {
          setFormLoaded(true);
          setLoaded(true);
          removeDropin();
        },
      })
      .mount('#adyen-dropin');
    setAdyen(dropin);
  }, [checkout, setAdyen, setFormLoaded]);

  return !isDeactivating ? <div id="adyen-dropin"></div> : <></>;
};
