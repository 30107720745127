import * as Sentry from '@sentry/react';
import { env } from 'constants/env';
import { Context as ContextType } from '@datadog/browser-core';
import { Datadog } from 'V2/Analytics';
import { LogToServiceType } from './types';

export const LogToService: LogToServiceType = {
  event: (eventName, eventAttributes) => {
    Datadog.logInfo(eventName, { ...eventAttributes });
  },

  captureException: ({ errorType, message, stack, thread, error }) => {
    if (env.clearcoverEnv !== 'production') {
      console.log('Logger:captureException: ', {
        errorType,
        message,
        stack,
        thread,
      });
    }

    const messageContext = {
      status: 'error',
      thread_name: thread,
      error: {
        kind: 'client',
        origin: 'custom',
        stack: `${stack}`,
      },
    } as ContextType;

    Datadog.logEvent({ level: 'error', message, messageContext });

    Sentry.withScope((scope) => {
      scope.setTag('ack_thread', thread);
      scope.setTag('ack_error_type', errorType);
      Sentry.captureException(error);
    });
  },
};
