import * as Sentry from '@sentry/react';
import version from 'version';
import { env } from 'constants/env';
import { identify } from 'V2/Utilities/Identify';
import { EntityType, FieldDataType, PropertiesType } from 'V2/Types';
import { FullStory } from './Analytics';

type SetAnalyticsType = (attributionData: PropertiesType) => void;

export const formatAttributionData = (
  attributionData: PropertiesType,
  defaultValue: string = ''
) => {
  return Object.keys(attributionData).reduce((data, key) => {
    if (key === 'current_user') return data;

    return {
      ...data,
      [`new_${key}`]: attributionData[key] ?? defaultValue,
    };
  }, {});
};

export const setAnalytics: SetAnalyticsType = (attributionData) => {
  const VERSION =
    env.nodeEnv === 'development' ? 'development' : version.git_sha;

  // we need to match what is currently in FullStory for
  // event properties on the client; we should be using
  // a pass through from the server instead of mapping
  // it in 2 places; we will tackle this down the line
  const prefixedData = formatAttributionData(attributionData);

  Sentry.setUser({
    id: attributionData.cc_uuid as string,
  });

  Sentry.setTags({
    version: VERSION,
    attribution_link_click_id: attributionData.attribution_link_click_id,
    marketing_id: attributionData.marketing_id,
    insurance_application_id: attributionData.insurance_application_id,
  });

  FullStory.setUserVars({
    version: VERSION,
    ...prefixedData,
  });

  if (!sessionStorage.marketingId && attributionData.marketing_id) {
    const marketingId = `${attributionData.marketing_id}`;

    sessionStorage.setItem('marketingId', marketingId);
    identify(marketingId);
  }
};

export const getStepCompleteInfo = async ({
  summary: { entities },
  current_step_submit_uri,
  field_data,
}: {
  summary: { entities: EntityType[] };
  current_step_submit_uri: string;
  field_data: FieldDataType[];
}) => {
  const isDriverVehicleStepCompleteUri = (uri: string = '') => {
    if (!uri) return false;

    const isCollectedSteps = uri.includes('collected_steps');
    const isEditing = uri.includes('edit');
    const isDriverOrVehicleStep =
      uri.includes('drivers') || uri.includes('vehicles');

    return isCollectedSteps && !isEditing && isDriverOrVehicleStep;
  };

  if (isDriverVehicleStepCompleteUri(current_step_submit_uri)) {
    const {
      entity_type: { id: type },
      entity_id: entityId,
    } = field_data[0];

    const isDriverEntity = (entity: EntityType) => {
      return (
        type === 'driver' &&
        entity.type.id === 'driver' &&
        entity.type.sub_type !== 'named_insured'
      );
    };

    const isVehicleEntity = (entity: EntityType) => {
      return type === 'vehicle' && entity.type.id === 'vehicle';
    };

    const filteredEntities = entities.filter(
      (e) => isDriverEntity(e) || isVehicleEntity(e)
    );

    const index = filteredEntities.findIndex((e) => e.id === entityId);

    if (index !== -1) {
      const stepInfo = { [`${type}Num`]: index + 1 };

      return { ...stepInfo };
    }
    return null;
  }
  return null;
};
