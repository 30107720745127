import React from 'react';
import styles from './styles.module.css';

export const FieldError: React.FC<{ field: string; message: string }> = ({
  field,
  message,
  children,
}) => {
  return <div className={styles.error}>{children}</div>;
};
