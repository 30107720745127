// See Axios documentation for more information on interceptors:
// https://axios-http.com/docs/interceptors

import {
  CreateResponseInterceptor,
  CreateResponseErrorInterceptor,
} from '../types';

// This function is used to create a response interceptor that will be used
// to intercept the response before it is returned to the client.
export const createResponseInterceptor: CreateResponseInterceptor = (
  additionalLogic
) => {
  return async (response) => {
    if (additionalLogic) {
      await additionalLogic(response);
    }

    return response;
  };
};

// This function is used to create a response error interceptor that will be used
// to intercept the response error before it is returned to the client.

export const createResponseErrorInterceptor: CreateResponseErrorInterceptor = (
  additionalLogic
) => {
  return async (error) => {
    if (additionalLogic) {
      additionalLogic(error);
    }

    return await Promise.reject(error);
  };
};
