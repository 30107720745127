import React, { useState, createContext, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { OptimizelyContext } from '@optimizely/react-sdk';
import { env } from 'constants/env';
import { LogToService } from 'V2/Utilities/Logger';
import { initApplication } from 'V2/Utilities/InitApplication';
import { agentAuth } from 'V2/Utilities/AgentAuth';
import { getQueryParams } from 'V2/Utilities/GetQueryParams';
import { contextDefaults } from 'V2/Step';
import { ApplyContextType, AppType } from './types';
import { summaryDefaults } from './Defaults';
import { removeSessionValuesFromLocalStorage } from 'V2/Utilities/StorageHelpers';
import { useFullstory } from 'V2/Hooks/useFullstory';
import { formatAttributionData } from 'V2/Analytics';

export const ApplyContext = createContext<ApplyContextType>({
  app: {
    appId: null,
    status: contextDefaults.status,
    config: contextDefaults.config,
    fields: [],
    summary: summaryDefaults,
  },
  setIsAnimating: () => {},
  isAnimating: false,
  setApp: () => {},
  resetApp: () => {},
  isReady: false,
});

export const useApplyProvider = () =>
  useContext<ApplyContextType>(ApplyContext);

export const ApplyProvider: React.FC = ({ children }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [app, setApp] = useState<AppType>({
    appId: null,
    status: contextDefaults.status,
    config: contextDefaults.config,
    fields: [],
    summary: summaryDefaults,
  });

  const resetApp = () => {
    setApp({
      ...app,
      status: contextDefaults.status,
      config: contextDefaults.config,
      fields: [],
      summary: summaryDefaults,
    });
  };

  const history = useHistory();
  const { optimizely } = useContext(OptimizelyContext);
  const { fsCommands } = useFullstory();

  // application bootstrap and attribution logic;
  // get attribution_link/data, set optimizely, set analytics;
  useEffect(() => {
    if (
      history.location.pathname === '/search' ||
      history.location.pathname === '/documents'
    )
      return;
    if (
      history.location.pathname === '/quote' &&
      ['development', 'ephemeral', 'staging', 'uat', 'partner'].includes(
        env.clearcoverEnv
      )
    )
      return;

    const {
      auth_token: authTokenParam,
      id: appIdParam,
      application_not_found: appNotFoundParam,
      click_id: clickIdParam,
    } = getQueryParams(history.location.search);

    // If these exist in localStorage remove them
    // Should only exist on /documents route
    removeSessionValuesFromLocalStorage(['consumerToken', 'appId', 'clickId']);

    if (appNotFoundParam === 'true') {
      sessionStorage.removeItem('appId');
      sessionStorage.removeItem('authToken');
      history.push('/search');
      return;
    }

    const isAgent = history.location.pathname === '/agent/apply';
    const agentAuthToken = localStorage.getItem('authToken');

    if (isAgent || agentAuthToken) {
      if (appIdParam && clickIdParam) {
        sessionStorage.setItem('appId', appIdParam);
        sessionStorage.setItem('clickId', clickIdParam);
        sessionStorage.removeItem('marketingId');
      }

      agentAuth()
        .then((token) => {
          localStorage.setItem('authToken', token);
          if (sessionStorage.getItem('appId')) {
            initApplication(optimizely)
              .then((attributionData) => {
                const prefixedData = formatAttributionData(
                  attributionData,
                  'none'
                );
                fsCommands.updatePersistedFullstoryMetadata(prefixedData);

                if (appIdParam && clickIdParam) {
                  history.push({ search: '' });
                }

                setIsReady(true);
              })
              .catch((error) => {
                LogToService.captureException({
                  errorType: 'client',
                  message: error.message,
                  thread: 'agentAuth::initApplication',
                  error: new Error(error),
                });

                window.location.href = env.portalHost;
              });
          } else {
            window.location.href = env.portalHost;
          }
        })
        .catch((error) => {
          LogToService.captureException({
            errorType: 'client',
            message: error.message,
            thread: 'agentAuth',
            error: new Error(error),
          });

          window.location.href = env.portalHost;
        });

      return;
    } else {
      // attribution bridge
      if (authTokenParam && appIdParam && clickIdParam) {
        // set new session locally
        sessionStorage.setItem('appId', appIdParam);
        sessionStorage.setItem('authToken', authTokenParam);
        sessionStorage.setItem('clickId', clickIdParam);
        sessionStorage.removeItem('marketingId');
      }

      if (sessionStorage.getItem('appId')) {
        initApplication(optimizely)
          .then((attributionData) => {
            const prefixedData = formatAttributionData(attributionData, 'none');
            fsCommands.updatePersistedFullstoryMetadata(prefixedData);

            if (appIdParam && clickIdParam) {
              history.push({ search: '' });
            }

            setIsReady(true);
          })
          .catch((error) => {
            LogToService.captureException({
              errorType: 'server',
              message: error.message,
              thread: 'initApplication',
              error: new Error(error),
            });

            history.push('/search');
          });
      } else {
        history.push('/search');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, optimizely]);

  useEffect(() => {
    const { product_segment } = app?.config?.features_by_key || {};
    const { quote_number } = app.summary?.metadata || {};

    fsCommands.updatePersistedFullstoryMetadata({
      product_segment: product_segment || 'none',
      new_insurance_application_id: app?.appId || 'none',
      new_quote_number: quote_number || 'none',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app?.config?.features_by_key?.product_segment, app?.appId]);

  useEffect(() => {
    fsCommands.setPersistedFullstoryPageProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  return (
    <ApplyContext.Provider
      value={{
        app,
        isAnimating,
        setIsAnimating,
        setApp,
        resetApp,
        isReady,
      }}
    >
      {children}
    </ApplyContext.Provider>
  );
};
