type HandlerData = Record<string, any>;
type EventHandler = (data: HandlerData) => void;

class EventDispatcher<TEvent extends string> {
  private static instance: EventDispatcher<any>;
  private events: Record<string, EventHandler[]> = {};
  private singleHandlerMode: boolean = false;
  private activeListeners = new Set<TEvent>();

  private constructor(singleHandlerMode: boolean = false) {
    this.singleHandlerMode = singleHandlerMode;
  }

  static getInstance<T extends string>(
    singleHandlerMode: boolean
  ): EventDispatcher<T> {
    if (!EventDispatcher.instance) {
      EventDispatcher.instance = new EventDispatcher<T>(singleHandlerMode);
    }
    return EventDispatcher.instance;
  }

  static createNewInstance<T extends string>(
    singleHandlerMode: boolean = false
  ): EventDispatcher<T> {
    return new EventDispatcher<T>(singleHandlerMode);
  }

  on(event: TEvent, handler: EventHandler) {
    if (this.singleHandlerMode && this.activeListeners.has(event)) {
      return;
    }

    if (!this.events[event]) {
      this.events[event] = [];
    }

    this.events[event].push(handler);
    this.activeListeners.add(event);
  }

  off(event: TEvent, handler: EventHandler) {
    if (this.events[event]) {
      this.events[event] = this.events[event].filter((h) => h !== handler);

      if (this.events[event].length === 0) {
        this.activeListeners.delete(event);
      }
    }
  }

  emit(event: TEvent, data: HandlerData) {
    if (this.events[event]) {
      this.events[event].forEach((handler) => handler(data));
    }
  }
}

export const createEventDispatcher = <T extends string>(
  singleHandlerMode: boolean = false
) => EventDispatcher.getInstance<T>(singleHandlerMode);

export const createNewEventDispatcher = <T extends string>(
  singleHandlerMode: boolean = false
) => EventDispatcher.createNewInstance<T>(singleHandlerMode);
