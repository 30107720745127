// See Axios documentation for more information on interceptors:
// https://axios-http.com/docs/interceptors

import {
  CreateRequestInterceptor,
  CreateRequestErrorInterceptor,
} from '../types';

// This function is used to create a request interceptor that will be used to
// intercept the request before it is sent to the server.
export const createRequestInterceptor: CreateRequestInterceptor = (
  additionalLogic
) => {
  return async (request) => {
    if (additionalLogic) {
      await additionalLogic(request);
    }

    return request;
  };
};

// This function is used to create a request error interceptor that will be used
// to intercept the request error before it is sent to the server.
export const createRequestErrorInterceptor: CreateRequestErrorInterceptor = (
  additionalLogic
) => {
  return async (error) => {
    if (additionalLogic) {
      additionalLogic(error);
    }

    return await Promise.reject(error);
  };
};
