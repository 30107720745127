import { useState, useCallback } from 'react';
import { http } from 'V2/Services/http';
import { Nullable } from 'V2/Types';
import { DataLayer, getStepCompleteInfo, setAnalytics } from 'V2/Analytics';
import { useFullstory } from 'V2/Hooks/useFullstory';
import {
  GetApiResourceType,
  PostApiResourceType,
  GetStatusType,
  FlowSequenceType,
  GetAnalyticsType,
} from './types';

export const useResources = () => {
  const [error, setError] = useState<Nullable<object>>(null);
  const { fsCommands } = useFullstory();

  const getResource: GetApiResourceType = useCallback(
    ({ url }) =>
      new Promise((resolve, reject) => {
        http
          .get({ url })
          .then((response) => resolve(response))
          .catch((error) => {
            setError(error);
            reject(error);
          });
      }),
    []
  );

  const postResource: PostApiResourceType = useCallback(
    ({ url, data }) =>
      new Promise((resolve, reject) => {
        http
          .post({ url, data })
          .then((response) => resolve(response))
          .catch((error) => {
            setError(error);
            reject(error);
          });
      }),
    []
  );

  const getStatus: GetStatusType = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const appId = sessionStorage.getItem('appId');
        http
          .get({
            url: `/api/v1/insurance_applications/${appId}/v2/status?include_details=true`,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            setError(error);
            reject(error);
          });
      }),
    []
  );

  const getAnalytics: GetAnalyticsType = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const appId = sessionStorage.getItem('appId');

        getResource({
          url: `/api/v1/insurance_applications/${appId}/analytics`,
        })
          .then((response) => {
            setAnalytics(response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    [getResource]
  );

  const flowSequence: FlowSequenceType = useCallback(
    () =>
      new Promise((resolve, reject) => {
        getStatus()
          .then(({ data }) => {
            DataLayer.push({
              event: data.step_status.id,
            });

            getStepCompleteInfo(data).then((stepCompleteInfo) => {
              if (stepCompleteInfo) {
                fsCommands.setPageProperties(stepCompleteInfo);
              }
            });

            setAnalytics(data.attribution_data);

            const whiteLabelData = data.step_status.white_label;

            if (whiteLabelData?.is_choice_flow) {
              sessionStorage.setItem(
                'white_label',
                JSON.stringify(whiteLabelData)
              );
            } else {
              sessionStorage.removeItem('white_label');
            }

            resolve({
              status: {
                ...data.step_status,
                partner: {
                  ...data.step_status.partner,
                  name: data.attribution_data.partner_name as string,
                },
              },
              config: {
                ...data.step_details,
                policy_enforced_state: data.attribution_data
                  .policy_enforced_state as string,
              },
              summary: data.summary,
            });
          })
          .catch((error) => {
            setError(error);
            reject(error);
          });
      }),
    [getStatus]
  );

  return {
    error,
    setError,
    getResource,
    postResource,
    getStatus,
    flowSequence,
    getAnalytics,
  };
};
