import { env } from 'constants/env';
import { FullStory } from 'V2/Analytics';

import { ConfigType, HttpType } from './types';
import { createAxiosInstance } from './AxiosConfig';
import { createRequestInterceptor } from './AxiosConfig/interceptors';
import { LogToService } from 'V2/Utilities/Logger';

declare global {
  interface Window {
    AwsWafIntegration: any;
  }
}

const axiosInstance = createAxiosInstance({
  baseURL: env.apiHost,
  onRequestFulfilled: createRequestInterceptor(async (request) => {
    try {
      const awsWafToken: string =
        window.AwsWafIntegration && (await window.AwsWafIntegration.getToken());

      if (!!awsWafToken) {
        request.headers.set('X-Aws-Waf-Token', awsWafToken);
      }
    } catch (error: any) {
      console.error('Error: ', error);
      LogToService.captureException({
        errorType: 'client',
        message: error.message,
        thread: 'Aws-Waf-Token',
        error: new Error(error),
      });
    }
  }),
});

const getDefaultHeaders = () => ({
  'Clearcover-Fullstory-Identity': FullStory.getCurrentSession() || '',
  ...(localStorage.getItem('authToken')
    ? {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      }
    : {
        'Clearcover-Applicant-Authorization-Token':
          sessionStorage.getItem('authToken') || '',
      }),
  'Clearcover-ClickId': sessionStorage.getItem('clickId') || '',
});

export const http: HttpType = {
  get: (config: ConfigType) =>
    axiosInstance.request({
      ...config,
      method: 'get',
      responseType: 'json',
      url: config.url,
      baseURL: env.apiHost,
      headers: {
        ...getDefaultHeaders(),
        ...config.headers,
      },
    }),
  post: (config: ConfigType) =>
    axiosInstance.request({
      ...config,
      method: 'post',
      responseType: 'json',
      url: config.url,
      baseURL: env.apiHost,
      headers: {
        ...getDefaultHeaders(),
        ...config.headers,
      },
    }),
};
