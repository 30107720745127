import { useCallback, useMemo, useState } from 'react';

import { FullStory } from 'V2/Analytics';
import { createEventDispatcher } from 'V2/Utilities/EventDispatcher';

type EventHandler = (data: FullstoryMetadata) => void;
type FullstoryCommand =
  | 'metadata-persisted:on-update-set-page-properties'
  | 'metadata-persisted:set-page-properties'
  | 'step-completed:vehicle-and-driver-steps'
  | 'metadata: set-page-properties';

export interface FullstoryMetadata {
  [key: string]: any;
}

const FS_METADATA_KEY = 'fs-fullstory-metadata';
const eventDispatcher = createEventDispatcher<FullstoryCommand>(true);

export function useFullstory() {
  const [metadata, setMetadata] = useState<FullstoryMetadata>(() => {
    const stored = sessionStorage.getItem(FS_METADATA_KEY);
    return stored ? JSON.parse(stored) : {};
  });

  const handlers: Record<FullstoryCommand, EventHandler> = useMemo(
    () => ({
      'metadata-persisted:on-update-set-page-properties': (data) => {
        FullStory.setPageVars(data);
      },
      'metadata-persisted:set-page-properties': (
        data: Partial<FullstoryMetadata>
      ) => {
        FullStory.setPageVars(data);
      },
      'step-completed:vehicle-and-driver-steps': (
        data: Partial<FullstoryMetadata>
      ) => {
        FullStory.track('step_complete', data);
      },
      'metadata: set-page-properties': (data) => {
        FullStory.setPageVars(data);
      },
    }),
    []
  );

  const ensureListenerIsActive = useCallback(
    (command: FullstoryCommand) => {
      eventDispatcher.on(command, handlers[command]);
    },
    [handlers]
  );

  const updatePersistedFullstoryMetadata = useCallback(
    (newData: Partial<FullstoryMetadata>) => {
      setMetadata((prev) => {
        const updated = { ...prev };

        // Merge new data with existing metadata, preventing overriding with 'none' values
        // if the key already has a valid value
        Object.keys(newData).forEach((key) => {
          if (newData[key] !== 'none' || !updated[key]) {
            updated[key] = newData[key];
          }
        });

        if (Object.keys(updated).length > 0) {
          sessionStorage.setItem(FS_METADATA_KEY, JSON.stringify(updated));

          ensureListenerIsActive(
            'metadata-persisted:on-update-set-page-properties'
          );
          eventDispatcher.emit(
            'metadata-persisted:on-update-set-page-properties',
            updated
          );
        }

        return updated;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setPersistedFullstoryPageProperties = () => {
    setMetadata((prev) => {
      const stored = sessionStorage.getItem(FS_METADATA_KEY);
      const metadata = stored ? JSON.parse(stored) : {};
      const updated = { ...prev };

      if (
        Object.keys(prev).length === 0 &&
        Object.keys(metadata).length === 0
      ) {
        return prev;
      }

      Object.keys(metadata).forEach((key) => {
        if (metadata[key] !== 'none' || !prev[key]) {
          updated[key] = metadata[key];
        }
      });

      if (Object.keys(updated).length > 0) {
        sessionStorage.setItem(FS_METADATA_KEY, JSON.stringify(updated));

        ensureListenerIsActive('metadata-persisted:set-page-properties');
        eventDispatcher.emit('metadata-persisted:set-page-properties', updated);
      }

      return updated;
    });
  };

  const trackVehicleAndDriverStepComplete = useCallback(
    (data: Partial<FullstoryMetadata>) => {
      ensureListenerIsActive('step-completed:vehicle-and-driver-steps');
      eventDispatcher.emit('step-completed:vehicle-and-driver-steps', data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setPageProperties = useCallback((data: FullstoryMetadata) => {
    ensureListenerIsActive('metadata: set-page-properties');
    eventDispatcher.emit('metadata: set-page-properties', data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    metadata,
    fsCommands: {
      setPersistedFullstoryPageProperties,
      updatePersistedFullstoryMetadata,
      trackVehicleAndDriverStepComplete,
      setPageProperties,
    },
  };
}
