import React, { ChangeEvent } from 'react';
import { Field } from 'formik';
import { FieldRenderProps } from '../types';
import { ToggleType } from './types';
import styled from 'styled-components/macro';
import {
  brand1Dark,
  brand2,
  brand3,
  brand3Light,
} from 'V2/Shared/Button/colorDefinitions';
import { transitionEaseIn, transitionEaseOut } from 'V2/Shared/Button/styles';

export const Toggles = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ToggleWrapper = styled.div`
  flex: 1 1 0;
  position: relative;
  margin-right: 12px;

  & > input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  & > label {
    display: block;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    background-color: var(--gray-20);
    border-radius: var(--border-radius);
    cursor: pointer;
    user-select: none;
    ${transitionEaseIn};
    box-shadow: 0 0 0 2px ${brand2};
  }

  & > input + label > span {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    font-size: 14px;
    text-transform: var(--button-text-transform);
    cursor: pointer;
    font-family: var(--font-family__secondary);
    font-weight: 500;
    text-align: center;
    height: 100%;
    width: 100%;

    color: var(--button-link-color);

    background-color: var(--button-toggle__bg);
    border: var(--button-toggle__border);
    border-radius: var(--border-radius);

    ${transitionEaseIn};
    transform: translateY(-1px);
  }

  & > input + label:hover {
    background-color: ${brand1Dark};
    ${transitionEaseOut};
    & > span {
      border: 1px solid var(--button-link-color);
      background-color: ${brand3Light};
      transform: translateY(-1px);
      ${transitionEaseOut};
    }
  }

  & > input:checked + label {
    background-color: ${brand1Dark};
    ${transitionEaseOut};
    & > span {
      border: 1px solid var(--button-link-color);
      background-color: ${brand3};
      transform: translateY(-1px);
      ${transitionEaseOut};
    }
  }

  & > input:focus + label {
    background-color: ${brand1Dark};
    outline: 2px solid #b3b6f9;
    outline-offset: 4px;

    & > span {
      border: 1px solid var(--button-link-color);
      background-color: ${brand3Light};
      transform: translateY(-1px);
    }
  }

  & > input:focus:not(:focus-visible) + label {
    box-shadow: none;
    outline: none;
    ${transitionEaseOut};

    & > span {
      border: 1px solid var(--button-link-color);
      background-color: ${brand3};
      transform: translateY(-1px);
      ${transitionEaseOut};
    }
  }

  & > input:active:not(:focus-visible) + label {
    & > span {
      border: 1px solid var(--button-link-color);
      background-color: ${brand3Light};
      transform: translateY(0px);
      ${transitionEaseOut};
    }
  }
`;

export const Toggle: React.FC<ToggleType> = ({
  options,
  name,
  onChange,
  ...inputProps
}) => (
  <Field name={name}>
    {({ field }: FieldRenderProps) => (
      <Toggles className="cc-toggle" data-testid="toggle">
        {options.map((option, i) => (
          <ToggleWrapper key={i} data-testid={`${name}-${option.value}`}>
            <input
              {...field}
              {...inputProps}
              className="cc-toggle__input"
              data-testid="input"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (onChange) {
                  onChange(event);
                }

                field.onChange(event);
              }}
              type="radio"
              name={name}
              value={`${option.value}`}
              id={`${name}-${option.value}`}
              checked={field.value.toString() === option.value}
            />
            <label
              className="cc-toggle__label"
              htmlFor={`${name}-${option.value}`}
              data-testid="label"
            >
              <span className="cc-toggle__label__span">{option.label}</span>
            </label>
          </ToggleWrapper>
        ))}
      </Toggles>
    )}
  </Field>
);
